/*
 * This file contains common JavaScript used by Asiakaslehti-site.
 */

$(function() {
    "use strict";

    // Set up owl carousel for article page's slider thumbnails.
    $(".owl-carousel.thumbnails").owlCarousel({
        margin: 15,
        navText: [
            "<i class='fa fa-chevron-left'></i>",
            "<i class='fa fa-chevron-right'></i>"
        ],
        responsive: {
            // breakpoint from 0 up
            0: {
                items: 3,
                nav: $(this).find(".thumbnail").length > 3
            },
            // breakpoint from 768 up
            768: {
                items: 6,
                nav: $(this).find(".thumbnail").length > 3
            }
        },
        responsiveClass: true
    });

    // Set up owl carousel for product sliders.
    $(".cmsplugin-product-highlight .owl-carousel").owlCarousel({
        items: 4,
        margin: 15,
        nav: true,
        navText: [
            "<i class='fa fa-chevron-left'></i>",
            "<i class='fa fa-chevron-right'></i>"
        ],
        responsive: {
            // breakpoint from 0 up
            0: {
                items: 2
            },
            // breakpoint from 768 up
            768: {
                items: 3
            },
            // breakpoint from 992 up
            992: {
                items: 4
            }
        },
        responsiveClass: true
    });

    // Add tooltip triggers to data-attribute html with data-toggle=tooltip
    $('[data-toggle="tooltip"]').tooltip({
        delay: { show: 500, hide: 100 }
    });

    // Enable lightbox for images. Use arrows to navigate through images if more
    // than one image available.
    $(".lightbox-images").each(function() {
        const selectorMultiple = "a[data-imagelightbox='image-multiple']";
        const selectorSingle = "a[data-imagelightbox='image']";
        const $this = $(this);

        const instanceMultiple = $(selectorMultiple, $this).imageLightbox({
            "onStart": function() {
                overlayOn();
                closeButtonOn(instanceMultiple);
                arrowsOn(instanceMultiple, selectorMultiple);
            },
            "onEnd": function() {
                overlayOff();
                closeButtonOff();
                arrowsOff();
            },
            "onLoadStart": function() {
                activityIndicatorOn();
            },
            "onLoadEnd": function() {
                $(".imagelightbox-arrow").show();
                activityIndicatorOff();
            }
        });

        const instanceSingle = $(selectorSingle, $this).imageLightbox({
            "onStart": function() {
                overlayOn();
                closeButtonOn(instanceSingle);
            },
            "onEnd": function() {
                overlayOff();
                closeButtonOff();
            },
            "onLoadStart": function() {
                activityIndicatorOn();
            },
            "onLoadEnd": function() {
                $(".imagelightbox-arrow").show();
                activityIndicatorOff();
            }
        });
    });

    // Set up certain <select>'s to use Bootstrap select picker.
    $("select.selectpicker").selectpicker();

    const stickyNav = function() {
        const $bannerHeight = $(".top-banner").outerHeight();
        const $navHeight = $(".main-navigation").outerHeight();
        const countedPoint = $bannerHeight + $navHeight + 100;

        // Add visible class 100px after scrolling past the ".main-navigation"
        $(".navbar-fixed-top").toggleClass(
            "visible",
            $(window).scrollTop() > countedPoint
        );
    };

    stickyNav();

    $(window).scroll(stickyNav);
});
